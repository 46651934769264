/* Importing Poppins Font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#app,
#app > div {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Poppins", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
}
p,
h1,
h2,
h3,
h4,
a,
li {
  letter-spacing: 0.05rem;
}

h1 {
  font-size: 32px;
  font-weight: 600;
}

h2 {
  font-size: 24px;
  font-weight: 400;
}

h3 {
  font-size: 20px;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input[type="text"]:focus {
  box-shadow: none;
}

input[type="number"] {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  /* border-bottom: 1px solid #262626; */
}

.chip {
  background: #a590e3 !important;
}

.option:hover {
  background: #a590e3 !important;
}

.modal-bg,
.modal-bg > p {
  background-color: white;
  font-size: 14px;
}
@layer components {
  .HollowedNumber {
    -webkit-text-stroke: 1.5px black;
  }
  .HollowedNumberLight {
    -webkit-text-stroke: 1px black;
  }

  .Card {
    background: #fcfcfc;
    /* BA Glow Effect */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1), 5px -1px 10px #f3f3f3;
    border-radius: 10px;
  }

  .Input {
    box-shadow: inset -4px -4px 4px #ffffff,
      inset 4px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    outline: none;
    border-bottom: none !important;
  }

  .btn-dashed-under:hover::before {
    content: "";
    @apply absolute bottom-[5px] left-0 right-0 z-[-1] h-full max-h-[7px] w-full bg-[#16DB93] lg:bottom-[10px];
  }
}

@layer base {
  /* Input placeholder text bold by default  */
  input::-webkit-input-placeholder {
    @apply font-bold;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  textarea:focus,
  input:focus {
    outline: none;
  }

  input[type="checkbox"]:focus {
    outline: 0;
  }

  /* Removes the cancel button in search field */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  /* Letter spacing of 0.05em for h1, h2, h3 */
  h1,
  h2,
  h3,
  p,
  button,
  label,
  input::-webkit-input-placeholder {
    @apply tracking-wider;
  }

  /* Overriding default style of dropdown checkbox */
  #css_custom {
    color: black;
    border: green;
  }

  .multiSelectContainer li {
    color: black;
    font-weight: 400;
    font-size: 12px;
  }

  .multiSelectContainer li:hover {
    background: rgba(181, 255, 184, 0.5) !important;
    color: black !important;
  }

  .chip {
    background: rgba(181, 255, 184, 0.5) !important;
    color: black !important;
  }

  .multiSelectContainer li {
    padding: 10px !important;
    font-size: 12px !important;
    vertical-align: middle !important;
  }

  .checkbox {
    margin-right: 10px !important;
    width: 15px;
    height: 15px;
  }

  #css_custom_input {
    color: #969696;
    font-size: 12px;
  }

  input[type="checkbox"] {
    accent-color: #16db93;
  }

  /* Overriding default style of scrollbar  */
  *::-webkit-scrollbar {
    width: 5px;
  }
  *::-webkit-scrollbar-track {
    background: white;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #262626;
    border-radius: 20px;
    border: 3px solid var(--primary);
  }
}

@layer utilities {
  @variants responsive {
    .text-shadow {
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000;
    }

    .text-shadow-none {
      text-shadow: none;
    }
  }
}

.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

/* Override default style of toggle  */
.react-toggle--checked .react-toggle-track {
  background-color: #16db93 !important;
}

.react-toggle-track {
  background-color: #c4c4c4 !important;
}

.react-toggle-thumb {
  border: none !important;
}
